import React, {useState, useEffect} from 'react';
import logo from "./logo.png";
import mLogo from "./logo_mobile.png";
import loader from "./loader.png";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import "./App.css";


// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyCwseobnMoynBU-BUVG8D2tn9smDC6Q_9o",
  authDomain: "ecossocialista-matosinhos.firebaseapp.com",
  projectId: "ecossocialista-matosinhos",
  storageBucket: "ecossocialista-matosinhos.appspot.com",
  messagingSenderId: "273239615848",
  appId: "1:273239615848:web:c4953c58908ebbe5fb0edd",
  measurementId: "G-KJ48FWXNJH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);


const q = query(collection(db, "botoes"));



const App = () => {

  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);
  const [botoes, setBotoes] =useState(null);

  useEffect(() => {
    const fetchBotoes = async () => {
      const botoes = await getDocs(q);
      setBotoes(botoes);
    }
    fetchBotoes();
  }, []);

  function handleOrientationChange(evt) {
    if (evt.matches) {
      setIsPortrait(true);
    } else {
     setIsPortrait(false);
    }
  }
  
  useEffect(() => {
    window.matchMedia("(orientation: portrait)").addEventListener("change", handleOrientationChange);
      return () => {
        window.matchMedia("(orientation: portrait)").removeEventListener("change", handleOrientationChange);
      }
  }, []);

  if(!botoes){
    return <div className="Wrapper"><img src={loader} className="Loader" alt="loader" /></div>;
  }
  
  return (
    <div className="App">
      <header key={"header"} className="App-header">
        {
          botoes?.docs.map((botao) => {
            const {name, url, text, active} = botao.data();
            return <a id={name} key={name} className={`${name}${active?'':'-disabled'}`} href={url}>{text}</a>;
          })
        }
        <div className="logo">
          <img src={isPortrait? mLogo: logo} className="App-logo" alt="logo" />
        </div>
      </header>
    </div>
  );
}

export default App;
